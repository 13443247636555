<template>
<!--   :class=' {noScoll: (termsUsePage || privacyAgreementPage)? true : false   }'-->
  <div class="rigister-page">
    <div class="page-center">
      <div class="login-form center">

<!--     主内容区    -->
        <div class="form-headerOne">
          <!--      底图   -->
          <div class="moduleOne">

<!--            <img-->
<!--              src='../../assets/images/website/imageOne.png'-->
<!--              class='imageOne'-->
<!--            />-->


            <div class="contentArea">

              <div class='topClass flex'>
                <div class='topLeft'>
                  <img
                    src='../../assets/images/website/logo.png'
                    class='wslogo'
                  >
                </div>

                <div class='topRight cursor-p' @click='gotoLogin'
                  v-if='!isMobile'
                >
                  登录
                </div>

<!--                 去到h5注册页面 -->
                <div class='topRight cursor-p' @click='gotoNewaccount'
                  v-else
                >
                  注册
                </div>

              </div>

              <div class='topText topTextFlex topFlexNone'>
                <div class='topTextl margintop'>
                  <div class='textL textOne mbOne'>人脉宝盒</div>
                  <div  class='textTwo mbTwo'>让人际关系更简单</div>
                  <div  class='textThree mbThree fs12'>
                    “人脉宝盒”是专门为个体经营者、职场商务人士、以及普通的忙碌人士量身定制，让用户组织自己的私人和专业网络圈子，是培养人际关系和找到工作与生活平衡的好方法。
                  </div>
                  <div class='xiazai'>
                    <div class='appDowld cursor-p'  @click="downloadApp('1')">
                      <img
                        src='../../assets/images/website/appIcon.png'
                        class='appIcon'
                        v-if='!isMobile'
                      >
                      <div class='appDowldText' v-if='!isMobile'>
                        下载
                      </div>
                      <div class='appDowldText' v-else>
                        下载APP
                      </div>

                      <!--               扫码下载app弹窗      -->
                      <div
                        class='qrCode'
                        v-show='showQrCode'
                        ref='isQrCode'
                      >
                        <img
                          src='../../assets/images/website/qrBac.png'
                          class='qrBac'
                        >

                        <div class='qrPso'>
                          <img
                            src='../../assets/images/website/appQrcode.png'
                            class='vueQr'
                          >
<!--                          <vue-qr-->
<!--                            :text="qrCodeLine"-->
<!--                            :margin="0"-->
<!--                            :size="128"-->
<!--                            :logoScale="99"-->
<!--                            class="vueQr"-->
<!--                            :logoSrc='qrLogoSrc'-->
<!--                          />-->
                          <div class='saoma'>请扫码下载APP</div>
                        </div>
                      </div>
                    </div>

                    <div class='appDowld cursor-p ml-20'  @click="downloadApp('ios')"      v-if='!isMobile'>
                      <img
                        src='../../assets/images/website/iosIcon.png'
                        class='appIconT'
                      >
                      <div class='appDowldText'>
                        下载
                      </div>

                      <!--               扫码下载苹果 app弹窗      -->
                      <div
                        class='qrCodeT'
                        v-show='showQrCodeIos'
                        ref='isQrCodeIos'
                      >
                        <img
                          src='../../assets/images/website/qrBac.png'
                          class='qrBac'
                        >

                        <div class='qrPsoT'>
                          <img
                            src='../../assets/images/website/iosQrcode.png'
                            class='vueQrT'
                          >
                          <div class='saoma'>请扫码下载APP</div>
                        </div>
                      </div>
                    </div>

                  </div>


                </div>

                <div class='topTextR'>
                  <img
                    v-if='isMobile'
                    src='../../assets/images/website/todayMb.png'
                    class='todayImage'
                  >

                  <img
                    v-else
                    src='../../assets/images/website/today.png'
                    class='todayImage'
                  >

                </div>
              </div>

            </div>
          </div>

          <!--     企业家展示部分    -->
          <div class='two'>
            <div class='qiyejiaO'>
              <div class='qiyejiaImage'>
                <img
                  src='../../assets/images/website/qiyejia.png'
                  class='qiyejia'
                >
              </div>
              <div class='qiyejiaText'>
                企业家
              </div>
            </div>

            <div class='qiyejiaO'>
              <div class='qiyejiaImage'>
                <img
                  src='../../assets/images/website/woshou.png'
                  class='qiyejia'
                >
              </div>
              <div class='qiyejiaText'>
                商务
              </div>
            </div>

            <div class='qiyejiaO'>
              <div class='qiyejiaImage'>
                <img
                  src='../../assets/images/website/lvshi.png'
                  class='qiyejia'
                >
              </div>
              <div class='qiyejiaText'>
                律师
              </div>
            </div>

            <div class='qiyejiaO'>
              <div class='qiyejiaImage'>
                <img
                  src='../../assets/images/website/daren.png'
                  class='qiyejia'
                >
              </div>
              <div class='qiyejiaText'>
                社交达人
              </div>
            </div>




          </div>
        </div>


      </div>

      <div  class="login-form centerWhite">
        <div class="center-header">
          <div class="contentArea">
            <div class='renTwo' v-if='!isMobile'>
              <div class="renContent">
                人脉宝盒，可以简化您的通信、安排您的日程，并接收有关即将发生事件的提醒，
                包括任务截止日期、会议、个人事务、生日等；她可以帮您与重要的联系人、
                客户或潜在客户建立定期联络，并帮助您保持稳固的关系，建立一个有组织、
                有计划人脉策略。
              </div>
            </div>

            <div v-else class="renContent">
              人脉宝盒，可以简化您的通信、安排您的日程，并接收有关即将发生事件的提醒，
              包括任务截止日期、会议、个人事务、生日等；她可以帮您与重要的联系人、
              客户或潜在客户建立定期联络，并帮助您保持稳固的关系，建立一个有组织、
              有计划人脉策略。
            </div>

          </div>
        </div>
      </div>

      <div  class="login-form center ">
        <div class="form-header">
          <div class="lingxingpositionL" v-if='!isMobile'>
            <img
              src='../../assets/images/website/lingxing.png'
              class='lingxing'
            >
          </div>
          <div class="contentArea">

            <div
              class='topText topTextFlex mtjian50'
              :class='{
              isFlex: isMobile
              }'

            >
              <div class='topTextl zIndex'
              >
                <div class='textOne'>繁杂的通讯录轻松分组</div>
<!--                <div  class='textTwo'>让人际关系更简单</div>-->
                <div  class='textThree textS'>
                  让您清晰的掌握自己的人脉关系全局
                </div>
              </div>
              <div class='topTextR'>
                <img
                  src='../../assets/images/website/renmai.png'
                  class='renmai'
                >
              </div>
            </div>

          </div>
        </div>
      </div>

      <div  class="login-form centerWhite">
        <div class="center-header paddingT ">
<!--           菱形定位 -->
          <div class="lingxingpositionR"
          v-if='!isMobile'
          >
            <img
              src='../../assets/images/website/lingxingR.png'
              class='lingxing'
            >
          </div>

          <div class="contentArea quanziHeight">
            <div class='topText contentFlex mtjian50'
             :class='{
              isFlex: isMobile
              }'
            >
              <div class='topTextl'>
                <img
                  src='../../assets/images/website/quanzi.png'
                  class='quanzi'
                >
              </div>
              <div class='topTextR fsBlack qingyi'>
                <div class='textOne fontWeight'>建立一个个小圈子</div>
                <div class='textOne fontWeight'>成为最受欢迎，最有情义的人</div>
                <!--                <div  class='textTwo'>让人际关系更简单</div>-->

                <div  class='textThree textS fontWeight'>
                  与一个个志同道合、兴趣相同、同窗战友、共同奋斗的朋
                </div>
                <div  class='textThree mt-10 fontWeight'
                      :class='{
                      fs: isMobile
              }'
                >
                  友们共筑友谊
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>

      <div  class="login-form center">
        <div class="form-header">
          <div class="contentArea">

            <div class='topText topTextFlex mtjian50'
                 :class='{
              isFlex: isMobile
              }'
            >
              <div class='topTextl'>
                <div class='textOne'>轻松管理任务</div>
                <div class='textOne'>简单记录历史时刻</div>

                <div  class='textThree textS'>
                  让您既不会遗漏任何一个待办，也不会遗忘任何一次交流与聚会的重要时刻
                </div>
<!--                <div  class='textThree textFs'>-->
<!--                  与聚会的重要时刻-->
<!--                </div>-->
              </div>
              <div class='topTextR'>
                <img
                  src='../../assets/images/website/shike.png'
                  class='shike'
                >
              </div>
            </div>

          </div>
        </div>
      </div>

      <div  class="login-form centerWhite">
        <div class="center-header paddingT">
          <div class="lingxingpositionL"
               v-if='!isMobile'
          >
            <img
              src='../../assets/images/website/lingxing.png'
              class='lingxing'
            >
          </div>

          <div class="contentArea quanziHeight">

            <div class='topText topTextFlex mtjian50'
                 :class='{
              isFlex: isMobile
              }'
            >
              <div class='topTextl zIndex'>
                <img
                  src='../../assets/images/website/todo.png'
                  class='todo'
                >
              </div>
              <div class='topTextR fsBlack ml-0 fontWeight'>
                <div class='textOne'>制定清晰的人脉策略</div>
                <div class='textOne'>提升时间利用率</div>

                <div  class='textThree textS'>
                  杜绝长期疲于应酬，却又忽略了更为重要的人
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div  class="login-form center">
        <div class="form-header formHeader-pa">
          <div class="lingxingpositionR"
               v-if='!isMobile'
          >
            <img
              src='../../assets/images/website/lingxingRB.png'
              class='lingxing'
            >
          </div>

          <div class="contentArea">

            <div class='topText flexCen mtjian50'>
              <div class='topTextl qicheng'>
                <div class='textOne'
                     :class='{
              isFs: isMobile
              }'
                >准备好启程了吗</div>
                <div class=' qichengxiazai'>
                  <div class='appDowld appDowldMr cursor-p'  @click="downloadApp('2')">
                    <img
                      src='../../assets/images/website/appIcon.png'
                      class='appIcon'
                      v-if='!isMobile'
                    >
                    <div class='appDowldText' v-if='!isMobile'>
                      下载
                    </div>
                    <div class='appDowldText' v-else>
                      下载APP
                    </div>

                    <!--               扫码下载app弹窗      -->
                    <div
                      class='qrCode'
                      v-show='showQrCodeT'
                      ref='isQrCodeT'
                    >
                      <img
                        src='../../assets/images/website/qrBac.png'
                        class='qrBac'
                      >

                      <div class='qrPso'>
                        <img
                          src='../../assets/images/website/appQrcode.png'
                          class='vueQr'
                        >
<!--                        <vue-qr-->
<!--                          :text="qrCodeLine"-->
<!--                          :margin="0"-->
<!--                          :size="128"-->
<!--                          :logoScale="80"-->
<!--                          class="vueQr"-->
<!--                          :logoSrc='qrLogoSrc'-->
<!--                        />-->
                        <div class='saoma'>请扫码下载APP</div>
                      </div>
                    </div>
                  </div>

                  <div class='appDowld appDowldMr cursor-p ml20'  @click="downloadApp('ios')"  v-if='!isMobile'>
                    <!--                  ios 图标-->
                    <img
                      src='../../assets/images/website/iosIcon.png'
                      class='appIconT'
                    >
                    <div class='appDowldText'>
                      下载
                    </div>

                    <!--               扫码下载苹果 app弹窗      -->
                    <div
                      class='qrCodeT'
                      v-show='showQrCodeIos'
                      ref='isQrCodeIos'
                    >
                      <img
                        src='../../assets/images/website/qrBac.png'
                        class='qrBac'
                      >

                      <div class='qrPsoT'>
                        <img
                          src='../../assets/images/website/iosQrcode.png'
                          class='vueQrT'
                        >
                        <div class='saoma'>请扫码下载APP</div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>
      </div>

      <div  class="login-form center footerBac">
        <div class="form-header formHeader-58">
          <div class="contentArea">

            <div class='topText borTextFlex mtjian50'
                 :class='{
                 mbHeight: isMobile
                   }'
            >
              <div class='topTextl' v-if='!isMobile'>

                <div class='topLeft'>
                  <img
                    src='../../assets/images/website/logo.png'
                    class='wslogo'
                  >
                </div>

              </div>
              <div class='topTextR'

              >
                <img
                  src='../../assets/images/website/QRcode.png'
                  class='QRcode'
                >
                <div class='mt-20 fontWeight fs-18'
                >联系我们</div>
              </div>

            </div>

          </div>
        </div>
      </div>

      <div  class="login-form center footerBac borderPrivacy">
        <div class="form-header ">
          <div class="contentArea">
            <!--        版权所有     -->
            <div class='topText borTextFlex mtjian50 borderPri'
                 :class='{
                 mbHeight: isMobile
                   }'
            >
              <div class='topTextl'>
                <div class='topLeft' v-if='!isMobile'>
                  深圳易思智科技有限公司<span class='ml-6 cursor-p' @click='gotoPrivacy'>©版权所有©2024粤ICP备13074986号-5</span>
                </div>

                <div class='topLeft' v-else>
                  <div >深圳易思智科技有限公司</div>
                  <div class='cursor-p' @click='gotoPrivacy'>©版权所有©2024粤ICP备13074986号-5</div>
                </div>

              </div>
              <div class='topTextR cursor-p'
              >
                <div  class='Privacy' @click='openPrivacyAgreementPage'  v-if='!isMobile'>隐私协议</div>
<!--             @click='openPrivacyAgreementPage'   -->
                <div  v-else @click='openPrivacyAgreementPageMb' >隐私协议</div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>

  </div>

</template>

<script>
import { ref, onMounted, reactive, toRefs, } from 'vue';
// import VueQr from 'vue-qr/src';
import { useRouter, useRoute } from 'vue-router';
import {
 message
} from 'ant-design-vue';
// import { validMobile, validEmail } from '@/utils/validation';
import todayApi from '@/api/today';
import router from '@/router';


export default {
  components: {
    // VueQr,

  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const isQrCode = ref();
    const isQrCodeIos = ref();
    const isQrCodeT = ref();
    const state = reactive({
      isMobile: false,
      qrCodeLine: '',
      showQrCode: false,
      showQrCodeIos: false,
      showQrCodeT: false,
      qrLogoSrc: require('../../assets/images/website/qrLogo.png'),

    });



    onMounted(() => {
      // getLatestVersionInfo();
      // 路由获取到 source  id
      const { id, source } = route.query;
      // console.log('id', id, source )
      if (id) {
        todayApi
          .saveWebsiteBatchVisitRecords('', {
            accessSource: source,
            id: id,
          })
          .then((res) => {
            // if (res) {
              console.log('res', res)
            //
            // }
          });
      }

    });

    const downloadApp = (str)=> {
      // let u = navigator.userAgent;
      todayApi
        .getLatestVersionInfo('', {})
        .then(res => {
          if (res) {
            if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
              // 移动端页面
              console.log('移动端页面')
              state.isMobile = true

              let u = navigator.userAgent;
              let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
              if (isIOS) {
                // 微信浏览器不支持跳转到App Store
                // 获取 User Agent
                let userAgent = u.toLowerCase();
                // 判断是否在微信中打开
                if (userAgent.indexOf('micromessenger') !== -1) {
                  // console.log('当前页面在微信中打开');
                  return message.info('请跳转到浏览器打开链接')
                } else {
                  // console.log('当前页面不在微信中打开');
                  // const appleID = 'com.renmaibaohe';
                  window.location.href = `https://apps.apple.com/cn/app/id6587572958`
                }

                // return message.info('APP暂不支持IOS，敬请期待。')
              } else {
                window.location.href = res.downloadUrl
              }

            } else {

              // pc端页面 扫码链接是h5页面
              state.qrCodeLine = process.env.VUE_APP_SERVER_LOGIN + 'mRigister'
              // state.qrCodeLine = res.downloadUrl
              if(str === '1') {
                state.showQrCode = true
              } else if (str === 'ios') {
                // const appleID = 'com.renmaibaohe';
                // window.location.href = `https://apps.apple.com/cn/app/id6587572958`
                state.showQrCodeIos = true
              } else {
                state.showQrCodeT = true
              }
            }
          }
        });

    };

    const gotoLogin = () => {
      router.push('/login');

    };

    const gotoNewaccount = () => {
      router.push('/mRigister');

    };


    onMounted(() => {
      // state.clientWidth = document.documentElement.clientWidth

      if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
        // 移动端页面
         console.log('移动端页面')
        state.isMobile = true

      }

      document.addEventListener('click', e => {
        if (isQrCode.value) {
          if (!isQrCode.value.contains(e.target)) {
            state.showQrCode = false;
          }
        }

        if (isQrCodeIos.value) {
          if (!isQrCodeIos.value.contains(e.target)) {
            state.showQrCodeIos = false;
          }
        }

        if (isQrCodeT.value) {
          if (!isQrCodeT.value.contains(e.target)) {
            state.showQrCodeT = false;
          }
        }

      });



    });
    return {
      ...toRefs(state),
      downloadApp,
      gotoLogin,
      gotoNewaccount,
      isQrCodeT,
      isQrCode,
      isQrCodeIos,
      gotoPrivacy() {
        // window.location.href = 'https://beian.miit.gov.cn/';
        window.open('https://beian.miit.gov.cn/', );

      },

      openPrivacyAgreementPage() {
        router.push({
          path: '/agreement',
        });
      },

      openPrivacyAgreementPageMb() {
        window.location.href = 'https://renmaibaohe.com/privacyAgreement.html'
      },
    };
  },
};
</script>
<style scoped lang="less">

// 移动端样式 todo
@media (max-width: 750px) {
  .moduleOne {
    width: 100%;
    height: auto;
    //height: 340px;
    background: url('../../assets/images/website/imageOneMb.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;

  }

  .rigister-page {
    height: 100vh;
    position: relative;
    background-color: #fafaf9;
    .page-top {
      box-sizing: border-box;
      padding: 3%;
      height: 10vh;
      .top-logo {
        justify-content: flex-start;
        width: 100%;

        .box-img {
          flex: 0 0 25%;

          img {
            width: 100%;
            object-fit: cover;
          }
        }

        text {
          flex: 1;
          color: #bfbfbf;
          letter-spacing: 2px;
          justify-content: flex-start;

          &::before {
            content: '';
            display: inline-block;
            margin: 0 3%;
            width: 1px;
            height: 10px;
            background-color: #333;
          }
        }
      }
    }
    .page-center {
      // 去掉外边距
      //padding: 2% 2% 0 2%;
      border-radius: 10px;
      background-color: #fff;
    }
    // 主内容区
    .center {
      //padding: 0 10% 20% 10%;
      //padding: 0 10% 6% 10%;
      //border-radius: 10px;

      width: 100%;
      margin: 0 auto;
      //min-height: calc(100vh - 55vh);
      // 渐变色
      background: linear-gradient( 44deg, #FB8E3A 0%, #FFD161 100%);
      .form-headerOne {
        position: relative;
        //padding: 15% 0 10% 0;
        margin: 0 auto;
        text-align: center;
        background: #fff;

        .person-avatar {
          position: absolute;
          top: -50%;
          left: 50%;
          transform: translate(-50%, 60%);
        }

        //   相对定位 企业家
        .two {
          background: #FFFFFF;
          border-radius: 20px;
          box-shadow: 0px 10px 50px 1px rgba(145, 70, 8, 0.2392);
          width: 375px;
          margin: 0 auto;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 16px 0px;
          position: absolute;
          top: 786px;

          /* left: -11%; */
          /* transform: translate(-50%, 1%); */

          margin: 0px 8px;

          .qiyejiaO {
            //padding: 30px 40px;
            width: 25%;
            text-align: center;
            .qiyejia {
              width: 42px;
              //height: 83px;
            }
            .qiyejiaText {
              font-size: 14px;
              margin-top: 14px;

            }

          }

        }
      }


      .form-header {
        position: relative;
        padding: 54px 14px;
        margin: 0 auto;
        text-align: center;

        .person-avatar {
          position: absolute;
          top: -50%;
          left: 50%;
          transform: translate(-50%, 60%);
        }
      }
      .formHeader-pa {
        padding: 74px 0px;
      }

      .formHeader-58 {
        padding: 30px 0px;
      }


    }
    // 白色底纹
    .centerWhite {
      width: 100%;
      margin: 0 auto;
      //min-height: calc(100vh - 55vh);
      background: #FFFFFF;

      // 渐变色
      //background: linear-gradient( 44deg, #FB8E3A 0%, #FFD161 100%);

      .center-header {
        position: relative;
        //padding: 12% 0 5% 0;
        margin: 0 auto;
        text-align: center;
        .contentArea {
          width: 375px;
          height: 314px;
        }
        .quanziHeight {
          height: auto;
        }

        .person-avatar {
          position: absolute;
          top: -50%;
          left: 50%;
          transform: translate(-50%, 60%);
        }
      }

      .paddingT {
        padding: 54px 14px;
      }
      .renContent {
        color: #FFFFFF;
        width: 380px;
        text-align: left;
        font-size: 14px;
        line-height: 28px;
        padding: 18px;
        position: absolute;
        top: 96px;
        /* right: -2px; */
        background: linear-gradient(55deg, #FB8E3A 0%, #FC9C42 45%, #FFD161 100%);
        border-radius: 18px 18px 18px 18px;
      }

    }

    .footerBac{
      background: #E07E0B;
    }

    .borderPrivacy {

      .form-header {
        padding: 0px 0px 20px 0px;
      }
      .Privacy {
        margin-right: 42px;
      }
    }


    .login-form {
      box-sizing: border-box;
      :deep(.ant-form label) {
        font-size: 16px;
      }
      // 底图
      //.moduleOne {
      //  width: 100%;
      //  height: 622px;
      //  background: url('../../assets/images/website/imageOne.png');
      //  background-size: 100% 100%;
      //  background-repeat: no-repeat;
      //  position: relative;
      //
      //}
      .topClass {
        padding-top: 24px;
        //padding-bottom: 55px;
        .topLeft {
          .wslogo {
            width: 120px;
          }
        }

        .topRight {
          background: #FF7F00;
          color: #FFFFFF;
          border-radius: 43px 43px 43px 43px;
          padding: 6px 38px;

        }

      }
      .topText {
        color: #FFFFFF;
        margin-top: 32px;

        .topTextl {
          .textOne {
            font-size: 16px;
            text-align: center;
            font-weight: 550;
          }
          .mbOne {
            text-align: center;
            font-size: 28px;
          }
          .isFs {
            font-size: 20px;
            font-width: 550;
          }

          .textTwo {
            font-size: 38px;
            text-align: left;
            margin: 10px 0px;
          }
          .mbTwo{
            font-size: 28px;
            text-align: center;
            font-weight: 550;
          }
          .textThree {
            font-size: 12px;
            text-align: left;
            line-height: 28px;
          }
          .mbThree {
            font-size: 14px;
          }
          .textS {
            margin-top: 14px;
            font-size: 12px;
          }
          .textFs {

            font-size: 12px;
          }
          .appDowld{
            background: #FFC721;
            //box-shadow: 0px 6 30px 1px rgba(56,30,0,0.22);
            border-radius: 43px 43px 43px 43px;
            width: 42%;
            padding: 8px 0px;
            //margin-top: 44px;

            display: flex;
            justify-content: center;
            align-items: center;
            margin: 28px auto 0px;

            .appIcon {
              width: 18px;
              height: 19px;
              margin-right: 6px;

            }
            .appIconT {
              width: 18px;
              height: 20px;
              margin-right: 6px;
            }
            .appDowldText {
              font-size: 18px
            }

          }
          .appDowldMr {
            margin: 32px auto 0px;
            width: 100%;
          }

          .quanzi {
            width: 167px;
            height: auto;
          }

          .todo {
            width: 174px;
            height: auto;
          }
          .topLeft {
            .wslogo {
              width: 166px;
            }
          }


        }

        .topTextR {

          .renmai {
            width: 165px;
            height: auto;
          }
          .textOne {
            font-size: 16px;
            text-align: left;
            font-weight: 550;
          }
          .textS {
            margin-top: 22px;
            font-size: 12px;
            font-weight: 550;
          }
          .fs{
            font-size: 12px;
            margin-top: 0px;
          }
          .shike {
            width: 216px;
            height: auto;
          }
          .QRcode {
            width: 120px;
            height: auto;
          }


        }

      }
      .mtjian50{
        margin-top: 0px;
      }
      //.topTextFlex {
      //  display: flex;
      //  justify-content: center;
      //  align-items: center;
      //}
      .isFlex{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .flexCen {
        display: flex;
        justify-content: center;
        align-items: center;

      }
      .fsBlack {
        color: #333333;
        text-align: left;
        margin-left: 38px;

      }
      .contentArea {
        width: 375px;
        height: auto;
        margin: 0 auto;

        .todayImage {
          //width: 388px;
          width: 100%;
          padding-top: 40px;
        }
      }
    }
    .title {
      font-size: 20px;
      font-weight: 600;
      color: #333;
    }

  }
  .fontWeight {
    font-weight: 550 !important;
  }

  //.mbHeight {
    //height: 328px;
  //}

}

// pc端样式
@media (min-width: 760px) {
  .rigister-page {
    height: 100vh;
    position: relative;
    background-color: #fafaf9;
    .page-top {
      box-sizing: border-box;
      padding: 3%;
      height: 10vh;
      .top-logo {
        justify-content: flex-start;
        width: 100%;

        .box-img {
          flex: 0 0 25%;

          img {
            width: 100%;
            object-fit: cover;
          }
        }

        text {
          flex: 1;
          color: #bfbfbf;
          letter-spacing: 2px;
          justify-content: flex-start;

          &::before {
            content: '';
            display: inline-block;
            margin: 0 3%;
            width: 1px;
            height: 10px;
            background-color: #333;
          }
        }
      }
    }
    .page-center {
      // 去掉外边距
      //padding: 2% 2% 0 2%;
      border-radius: 10px;
      background-color: #fff;
    }
    // 主内容区
    .center {
      //padding: 0 10% 20% 10%;
      //padding: 0 10% 6% 10%;
      //border-radius: 10px;

      width: 100%;
      margin: 0 auto;
      //min-height: calc(100vh - 55vh);
      // 渐变色
      background: linear-gradient( 44deg, #FB8E3A 0%, #FFD161 100%);
      .form-headerOne {
        position: relative;
        //padding: 15% 0 10% 0;
        margin: 0 auto;
        text-align: center;
        background: #fff;

        .person-avatar {
          position: absolute;
          top: -50%;
          left: 50%;
          transform: translate(-50%, 60%);
        }

        //   相对定位 企业家
        .two {
          background: #FFFFFF;
          border-radius: 20px;
          box-shadow: 0px 10px 50px 1px rgba(145, 70, 8, 0.2392);
          width: 884px;
          margin: 0 auto;

          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 16px 0px;
          position: absolute;
          top: 600px;
          left: 50%;
          transform: translate(-50%, 1%);

          .qiyejiaO {
            //padding: 30px 40px;
            width: 25%;
            text-align: center;
            .qiyejia {
              width: 80px;
              //height: 83px;
            }
            .qiyejiaText {
              font-size: 18px;
              margin-top: 14px;

            }

          }

        }
      }


      .form-header {
        position: relative;
        padding: 90px 0px;
        margin: 0 auto;
        text-align: center;

        .person-avatar {
          position: absolute;
          top: -50%;
          left: 50%;
          transform: translate(-50%, 60%);
        }
        //菱形的绝对定位
        .lingxingpositionL {
          position: absolute;
          top: -387px;
          left: -464px;
          .lingxing {
            width: 878px;
            height: auto;
          }
        }
        .lingxingpositionR {
          position: absolute;
          top: 135px;
          right: 0px;
          .lingxing {
            width: 167px;
            height: auto;
          }
        }
      }
      .formHeader-pa {
        padding: 132px 0px;
      }

      .formHeader-58 {
        padding: 58px 0px;
      }


    }
    // 白色底纹
    .centerWhite {
      width: 100%;
      margin: 0 auto;
      min-height: calc(100vh - 55vh);
      background: #FFFFFF;

      // 渐变色
      //background: linear-gradient( 44deg, #FB8E3A 0%, #FFD161 100%);

      .center-header {
        position: relative;
        padding: 12% 0 5% 0;
        margin: 0 auto;
        text-align: center;
        .contentArea {
          width: 884px;
          height: 314px;
            .contentFlex {
              display: flex;
              align-items: center;
              justify-content: center;
            }
        }
        .quanziHeight {
          height: auto;
        }

        .person-avatar {
          position: absolute;
          top: -50%;
          left: 50%;
          transform: translate(-50%, 60%);
        }
        //  右侧菱形定位
        .lingxingpositionR {
          position: absolute;
          top: -166px;
          right: 0px;
          .lingxing {
            width: 372px;
            height: auto;
          }
        }
        .lingxingpositionL {
          position: absolute;
          top: -274px;
          left: -432px;
          .lingxing {
            width: 1000px;
            height: auto;
          }
        }
      }
      .paddingT {
        padding: 90px 0px;
      }
      .renTwo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        background: url('../../assets/images/website/xiezi.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: relative;
        z-index: 2;

        .renContent {
          color: #FFFFFF;
          width: 575px;
          text-align: left;
          font-size: 19px;
          padding: 21px 0px;
          position: absolute;
          top: 50px;
          right: 50px;
          line-height: 44px;
        }

      }





    }

    .footerBac{
      background: #E07E0B;
    }
    .borderPrivacy {

      .form-header {
        padding: 0px 0px 20px 0px;
      }
      .Privacy {
        margin-right: 42px;
      }
    }

    .login-form {
      box-sizing: border-box;
      :deep(.ant-form label) {
        font-size: 16px;
      }
      // 底图
      .moduleOne {
        width: 100%;
        height: 622px;
        background: url('../../assets/images/website/imageOne.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: relative;
      }
      .topClass {
        padding-top: 50px;
        padding-bottom: 50px;
        .topLeft {
          .wslogo {
            width: 166px;
          }
        }


        .topRight {
          background: #FF7F00;
          color: #FFFFFF;
          border-radius: 43px 43px 43px 43px;
          padding: 8px 38px;

        }

      }
      .topText {
        color: #FFFFFF;
        .topTextl {
          .textOne {
            font-size: 30px;
            text-align: center;
            font-weight: 550;
          }
          .textL {
            text-align: left !important;
          }
          .textTwo {
            font-size: 38px;
            text-align: left;
            margin: 10px 0px;
            font-weight: 550;
          }
          .textThree {
            font-size: 14px;
            text-align: left;
            line-height: 28px;
          }
          .fs12 {
            font-size: 12px;
          }
          .textS {
            margin-top: 58px;
            font-size: 15px;
          }
          .textFs {
            margin-top: 10px;
            font-size: 15px;
          }
          .appDowld{
            background: #FFC721;
            //box-shadow: 0px 6 30px 1px rgba(56,30,0,0.22);
            border-radius: 43px 43px 43px 43px;
            width: 38%;
            padding: 8px 0px;
            margin-top: 44px;

            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .appIcon {
              width: 18px;
              height: 19px;
              margin-right: 6px;

            }
            .appIconT {
              width: 18px;
              height: 20px;
              margin-right: 6px;
            }
            .appDowldText {
              font-size: 18px
            }
            .xiazai {
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }

            .ml20 {
              margin-left: 20px;
            }
          }
          .qrCode{
            position: absolute;
            top: 36px;
            z-index: 2;

            //width: 100%;
            //height: 252px;
            //background: url('../../assets/images/website/qrBac.png');
            //background-size: 100% 100%;
            //background-repeat: no-repeat;

            .qrBac {
              width: 212px;
              height: 248px;
            }
            .qrPso {
              position: absolute;
              top: 41px;
              left: 36px;

              .vueQr {
                //   加个黑边框，边距2px
                //border: 2px solid #333333 ;
                padding: 4px;
                border-radius: 6px;
                width: 140px;
                height: 140px;
              }
              .saoma {
                margin-top: 6px;
                color: #333333;
                font-size: 18px;
                font-weight: 500;
                font-style: normal;
              }
            }

          }

          .qrCodeT {
            position: absolute;
            top: 36px;
            z-index: 2;

            //width: 100%;
            //height: 252px;
            //background: url('../../assets/images/website/qrBac.png');
            //background-size: 100% 100%;
            //background-repeat: no-repeat;

            .qrBac {
              width: 212px;
              height: 248px;
            }
            .qrPsoT {
              position: absolute;
              top: 41px;
              left: 36px;
              .vueQrT {
                //   加个黑边框，边距2px
                //border: 2px solid #333333 ;
                padding: 4px;
                border-radius: 6px;
                width: 140px;
                height: 140px;
              }
              .saoma {
                margin-top: 6px;
                color: #333333;
                font-size: 18px;
                font-weight: 500;
                font-style: normal;
              }
            }

          }

          .appDowldMr {
            margin: 50px auto 0px;
            width: 86%;
          }

          .quanzi {
            width: 480px;
            height: auto;
          }

          .todo {
            width: 480px;
            height: auto;
          }
          .topLeft {
            .wslogo {
              width: 166px;
            }
          }


        }
        .zIndex {
          z-index: 2;
        }
        .margintop {
          margin-top: 60px;
        }
        .topTextR {

          .renmai {
            width: 480px;
            height: auto;
          }
          .textOne {
            font-size: 28px;
            text-align: left;
            font-weight: 550;
          }
          .textS {
            margin-top: 58px;
            font-size: 14px;
            font-weight: 500;
          }
          .shike {
            width: 480px;
            height: auto;
          }
          .QRcode {
            width: 120px;
            height: auto;
          }


        }

      }
      .topTextFlex {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .topFlexNone {
        align-items: flex-start;
        //margin-top: 50px;
      }
      .borTextFlex {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .flexCen {
        display: flex;
        justify-content: center;
        align-items: center;

      }
      .fsBlack {
        color: #333333;
        text-align: left;
        margin-left: 38px;

      }
      .qingyi {
        z-index: 2;
      }
      .contentArea {
        width: 884px;
        height: auto;
        margin: 0 auto;
          .contentFlex {
            display: flex;
            align-items: center;
            justify-content: center;
          }

        .todayImage {
          width: 478px;
          //padding-top: 113px;

        }
      }
    }
    .title {
      font-size: 20px;
      font-weight: 600;
      color: #333;
    }

  }
  .fontWeight {
    font-weight: 550 !important;
  }

  .qicheng {
    width: 300px;
  }
  .xiazai {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .qichengxiazai {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    .appDowldMr {
      width: 45% !important;
    }
  }

  .ml20 {
    margin-left: 20px;
  }

}

// 1367
@media screen  and (min-width: 1550px) and (max-width: 1920px) {
  .topClass {
    padding-top: 50px;
  }
  .two {
    top: 612px !important;
  }
  .todayImage {
    margin-top: 8px;
  }

}



</style>
